import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import LogoIcon from "./LogoIcon"
import ShapeCrescent from "./shapes/Crescent"

const FooterWrap = styled.footer `
  background-color: ${({ theme }) => theme.colorResetBlack};
  color: #fff;
  padding: 5rem 2rem;
  position: relative;
`

const FooterContent = styled.div `
  background-color: ${({ theme }) => theme.colorResetBlack};
  color: #fff;
  max-width: 945px;
  margin: auto;

  @media (min-width: 768px) {
    display: flex;
  }
`

const FooterInfo = styled.div `
  max-width: 800px;
  margin: auto;
  text-align: center;

  a:link, a:visited {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.colorResetWhite};
  }
`

const Contacts = styled.ul `
  list-style: none;
  margin: 0;
  padding: 0;
`

const Social = styled.ul `
  list-style: none;
  margin:  4rem auto;
  padding: 0;
  display: flex;
  justify-content: center;

  li {
    font-size: 30px;
    margin: 0 0.5rem;
  }
`

const CreativeCommons = styled.p `
  font-size: ${({ theme }) => theme.FontTiny};

  a:link {
    text-decoration: underline;
  }
`

const LogoWrap = styled.div `
  text-align: center;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    text-align: left;
    margin: 0;
  }
`

const Shape = styled.div `
  position: absolute;
  right: -150px;
  bottom: 70%;
  transform: rotate(-50deg);  
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1220px) {
    bottom: 10%;
  }
`

const Footer = () => (
  
  <FooterWrap>
    <FooterContent>

      <Shape>
        <ShapeCrescent />
      </Shape>

      <LogoWrap>
        <LogoIcon />
      </LogoWrap>

      <FooterInfo>

        <h3>Get in touch</h3>

        <p>For all press enquiries, please contact:</p>

        <Contacts>
          <li>Ruth Potts: <a href="mailto:info@greennewdeal.org.uk">info@greennewdeal.org.uk</a></li>
        </Contacts>

        <Social>
          <li><a href="https://www.facebook.com/ResetUK"><FontAwesomeIcon aria-label="External link to Facebook" role="button" icon={faFacebook} /></a></li>
          <li><a href="https://twitter.com/ResetUKLife"><FontAwesomeIcon aria-label="External link to Twitter" role="button" icon={faTwitter} /></a></li>
          <li><a href="https://www.instagram.com/resetuklife/"><FontAwesomeIcon aria-label="External link to Instagram" role="button" icon={faInstagram} /></a></li>
        </Social>

        <p>© Green New Deal Group, {new Date().getFullYear()}.</p>

        <p>Website design and build by <a href="https://curiousways.com/">Curious Ways</a>.</p>  

        <CreativeCommons>Photos are released under <a href="https://meet.google.com/linkredirect?authuser=0&dest=https%3A%2F%2Fcreativecommons.org%2Flicenses%2Fby%2F3.0%2F">Creative Commons Attribution 3.0 Unported (CC BY 3.0) licence</a>. This means that you can use, share and adapt it, as long as you give proper attribution, provide a link to the licence, and indicate if changes were made to the image. <a href="https://creativecommons.org/licenses/by/3.0/">See the full licence for more details</a></CreativeCommons>

      </FooterInfo> 

    </FooterContent>
    
  </FooterWrap>

)

export default Footer