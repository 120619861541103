import { Link } from "gatsby"
import React from "react"
import Logo from "./Logo"
import styled from "styled-components"

const Wrap = styled.header`
  background-color: ${({ theme }) => theme.colorResetGreen};
  border-top: 10px solid ${({ theme }) => theme.colorResetBlue};
  position: relative;
`

const Content = styled.div`
  max-width: 1300px;
  margin: auto;
`

const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3rem;
`

const Menu = styled.ul`
  display: flex;
  text-decoration: none;
  justify-content: center;
  margin-top:2rem;
  margin-left: 0;
`

const MenuItem = styled.li`
  list-style: none;
  padding: 0 1.5rem 0 1.5rem;

  @media (max-width: 450px) {
    padding: 0 0.5rem 0 0.5rem;
  }  
`

const MenuLink = styled(Link)`
    font-weight: 700;
    text-decoration: none;
    color: ${({ theme }) => theme.colorResetBlue};
`

const Header = () => (

  <Wrap>
    <Content>

      <Menu>
        <MenuItem key="1"><MenuLink to="/">Home</MenuLink></MenuItem>
        <MenuItem key="2"><MenuLink to="/reports">Reports</MenuLink></MenuItem>
        <MenuItem key="3"><MenuLink to="/evidence">Evidence</MenuLink></MenuItem>
        <MenuItem key="4"><MenuLink to="/about">About</MenuLink></MenuItem>
      </Menu>

      <LogoWrap>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrap>

    </Content>
  </Wrap>

)

export default Header