/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../fonts/fonts.css"
import "typeface-muli"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from '../global';
import { theme } from '../theme';
import Header from "./SiteHeader"
import Footer from "./Footer"

const Layout = ({ children }) => {

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />

        <Header />

        <main>{children}</main>

        <Footer />

      </ThemeProvider>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
