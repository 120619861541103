export const theme = {

  // Colors
  colorResetBlack: '#3A3A3A',
  colorResetWhite: '#F3ECE2',
  colorResetYellow: '#F9B233',
  colorResetGreen: '#00E17E',
  colorResetRed: '#DD6A5C',
  colorResetBlue: '#5000EA',

  // Fonts
  fontDisplay: '"Gellix", "sans-serif"',
  fontRegular: '"Muli", "sans-serif"',

  // Font sizes
  FontXlarge: "2.5rem", // 40px
  FontLarge: "1.88rem", // 30px
  FontMedium: "1.375rem", // 22px
  FontRegular: "1.125rem", // 18px
  FontSmall: "1rem", // 16px
  FontTiny: "0.875rem", // 14px  

  // Breakpoints
  ScreenTiny: "max-width: 500px",
  ScreenSmall: "max-width: 767px",
  ScreenMedium: "min-width: 768px",
  ScreenLarge: "min-width: 1024px",
  ScreenWide: "min-width: 1400px",

  // Widths
  WidthContent: "1250px",

  // Padding
  PadPanel: "6.25rem 2rem 9.375rem",

}

